import 'flatpickr/dist/flatpickr.min.css';
import "swiper/swiper-bundle.css";
import "photoswipe/dist/photoswipe.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../sdist/tailwind.css";

import Swiper, { Navigation, Pagination, Thumbs } from "swiper";

import 'htmx.org';
window.htmx = require('htmx.org');
require('../node_modules/htmx.org/dist/ext/alpine-morph.js');

import Alpine from "alpinejs";
import initPhotoSwipeFromDOM from './js/photoswipe.js';
import collapse from "@alpinejs/collapse";
import focus from '@alpinejs/focus'
import morph from '@alpinejs/morph'
import persist from '@alpinejs/persist'


import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

window.Alpine = Alpine;
Alpine.plugin(persist)
Alpine.plugin(morph);
Alpine.plugin(focus);
Alpine.plugin(collapse);



window.addEventListener("load", function () {

  if (document.getElementsByClassName('gallery-main').length) {
    var galleryThumbs = {};

    if (document.getElementsByClassName('gallery-thumbs-1x').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs-1x', {
        modules: [Navigation],
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
            direction: 'vertical',
          },
        }
      })};
    } else if (document.getElementsByClassName('gallery-thumbs-3x').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs-3x', {
        modules: [Navigation],
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
            direction: 'vertical',
            centeredSlides: true,
          },
        }
      })};
    }

    var galleryTop = new Swiper('.gallery-main', {
      modules: [Navigation, Thumbs, Pagination],
      spaceBetween: 0,
      preloadImages: false,
      pagination: {
        el: '.gallery-main-indicator',
        type: 'fraction',
      },
      // Enable lazy loading
      lazy: true,
      thumbs: galleryThumbs,
    });

    initPhotoSwipeFromDOM('.gallery-main');

  }

  initPhotoSwipeFromDOM('.gallery-line');

});

let datetimes = flatpickr(".datetimeinput", {
  "locale": German,
  enableTime: true,
  dateFormat: "d.m.Y H:i",
});

let dates = flatpickr(".dateinput", {
  "locale": German,
  enableTime: false,
  dateFormat: "d.m.Y",
});

function reloadFlatpickr() {
  if (dates) {
    dates.forEach(
      date => date.destroy()
    );
  }

  dates = flatpickr(".dateinput", {
    "locale": German,
    enableTime: false,
    dateFormat: "d.m.Y",
  });

  if (datetimes) {
    datetimes.forEach(
      date => date.destroy()
    );
  }

  datetimes = flatpickr(".datetimeinput", {
    "locale": German,
    enableTime: true,
    dateFormat: "d.m.Y H:i",
  });

}


Alpine.start();
